import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        We are coming soon to make
        <div id='flip'>
          <div><div>your work</div></div>
          <div><div>Everything</div></div>
          <div><div>YOU</div></div>
        </div>
        AWESOME!
      </header>
    </div>
  );
}

export default App;
